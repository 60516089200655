webClient.controller('AirPaymentBodyBlue', ['$scope', '$window', '$timeout', '$i18next', '$filter', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'trackingService', 'gtmProduct', 'omnitureService',
    function ($scope, $window, $timeout, $i18next, $filter, tseApiClient, tseUtil, airHelper, trackingService, gtmProduct, omnitureService) {
        $scope.getText = function (key) { return window.culture.texts[key]; };
        var searchUrl = $window.site.currentAirRequest.searchUrl + "?" + tseUtil.Location.getQueryString();
        var bkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);
        $scope.bkgData = bkgData;
        $scope.getTemplateUrl = $window.getTemplateUrl;
        $scope.siteType = $window.site.type;
        var siteTypeIsB2b = $window.site.type === 2;
        window.$scope = $scope;
        window.displayBrandedFaresSummary = true;
        $scope.isAlertServair = window.site.partnerId === 'servair';
        $scope.enableUpSellProducts = $window.enableUpSellProducts || $window.sessionStorage.getItem('forceUpsell') === 'true';
        $scope.accommodation = null;
        $scope.pspAccountId = null;
        $scope.isUpsell = $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';

        $scope.blue = {};
        $scope.blue.errorCvv = true;
        $scope.blue.errorNumber = true;
        $scope.blue.errorDate = true;

        $scope.blue.isError = function () {
            return $scope.blue.errorCvv || $scope.blue.errorNumber || $scope.blue.errorDate;
        };
        bkgData.totalBasketPrice = function () {

            if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {
                // mandatory Options
                var proposal = $scope.bkgData.purchaseResponse.proposalsRequested;
                var mandatoryOptionsFees = 0;
                if (proposal.mandatoryFees) {
                    mandatoryOptionsFees = proposal.mandatoryFees.reduce(function (accumulator, mandatoryFee) {
                        return accumulator + mandatoryFee.totalPrice;
                    }, 0);
                }
                return bkgData.prixTotal.value + bkgData.accommodations[0].totalPriceForcedPrice + mandatoryOptionsFees;
            } else {
                return bkgData.prixTotal.value;
            }
        };

        getBundleWidget("mrfly-widget-loader.js").done(function () {

        });
        var hfields = null;
        $scope.isAirbus = $window.isAirbus;
        $scope.isSafran = $window.isSafran;

        //Tracking
        try {
            //GTM
            if (trackingService.isActivated()) {
                trackingService.initDataLayer('checkout');
                trackingService.loadSessionData('step', 3);
                trackingService.setOption($scope.bkgData.bookingResponse.paymentMethod.paymentMethodName);
                trackingService.parseScope($scope);
                trackingService.pushDataLayer('step', 4);

                if (trackingService.pageViewEvent) {
                    trackingService.pageViewEvent("checkout_page", "airpayment");
                }

                if (trackingService.interactionEvent) {
                    trackingService.interactionEvent("funnel_step3", "secure payment", "3");
                }
            }

            //omniture
            if (omnitureService.isActivated()) {
                omnitureService.sendEvent(omnitureService.PURCHASE_4_VOL, omnitureService.FLIGHT_TUNNEL, null);
            }

            if (window.site.partnerId == 'cdiscountvol') {

                var departingDepartureDate = moment($scope.bkgData.purchaseResponse.itineraries.first().departureDateTime).format("YYYY-MM-DD");
                var departingArrivalDate = moment($scope.bkgData.purchaseResponse.itineraries.first().arrivalDateTime).format("YYYY-MM-DD");
                var returningDepartureDate = $scope.bkgData.request.oneWay ? '' : moment($scope.bkgData.purchaseResponse.itineraries.last().departureDateTime).format("YYYY-MM-DD");
                var returningArrivalDate = $scope.bkgData.request.oneWay ? '' : moment($scope.bkgData.purchaseResponse.itineraries.last().arrivalDateTime).format("YYYY-MM-DD");

                var cabinClassName = function (item) {
                    switch (item) {
                        case "Y":
                            return "economy";
                        case "S":
                            return "premium";
                        case "C":
                            return "business";
                        case "F":
                            return "first";
                        default:
                            return "economy";
                    }
                };
                function waitForfbq() {
                    if (typeof fbq === "undefined") {
                        requestAnimationFrame(waitForfbq);
                        return;
                    }

                    fbq('track', 'InitiateCheckout', {
                        type_service: 'VOYAGES',
                        content_category: 'VOL',
                        content_type: 'flight',
                        content_ids: $scope.bkgData.request.origin.cityIATA + '-' + $scope.bkgData.request.destination.cityIATA,
                        value: $scope.bkgData.prixTotal.value,
                        currency: 'EUR',
                        origin_airport: $scope.bkgData.purchaseResponse.itineraries[0].origin.airportIata,
                        destination_airport: $scope.bkgData.purchaseResponse.itineraries[0].destination.airportIata,
                        departing_departure_date: departingDepartureDate,
                        returning_departure_date: returningDepartureDate,
                        departing_arrival_date: departingArrivalDate,
                        returning_arrival_date: returningArrivalDate,
                        num_adults: $scope.bkgData.request.adults > 0 ? $scope.bkgData.request.adults : 0,
                        num_children: $scope.bkgData.request.childs > 0 ? $scope.bkgData.request.childs : 0,
                        num_infants: $scope.bkgData.request.infants > 0 ? $scope.bkgData.request.infants : 0,
                        travel_class: cabinClassName($scope.bkgData.request.cabinClass),
                        compagnie: $scope.bkgData.purchaseResponse.itineraries[0].marketingCarrier.name,
                        preferred_num_stops: $scope.bkgData.purchaseResponse.itineraries[0].stops
                    });
                }

                requestAnimationFrame(waitForfbq);

            }
        }
        catch (ex) {
            console.log(ex.message);
            $window.trackingError = ex.message;
        }

        $scope.init = function (pspAccountId) {
            $scope.pspAccountId = pspAccountId;
        }

        $scope.sendNeoDataLayer = function () {
            if (trackingService.isActivated() && window.partnerName == "aeroports-voyages") {
                trackingService.neoDataLayer('proceed_to_payment');

            }
            
        }

        $scope.saveSentryData = function (paymentError, paymentLogWidgetType, message, requestId, purchaseId, paymentId, paymentMethod, bookingId) {
            Raven.captureException(message, {
                extra: {
                    Payment:
                    {
                        requestId: requestId,
                        purchaseId: purchaseId,
                        paymentId: paymentId,
                        paymentMethod: paymentMethod,
                        bookingId: bookingId,
                        site: window.site.partnerId,
                        step: 4
                    }
                },
                tags: {
                    paymentLogType: paymentError,
                    paymentLogWidgetType: paymentLogWidgetType,
                    requestId: requestId,
                    purchaseId: purchaseId,
                    paymentId: paymentId,
                    paymentMethod: paymentMethod,
                    bookingId: bookingId,
                    site: window.site.partnerId,
                    step: 4
                }
            })
        }
        var getBasketDetails = function () {
            var siteTypeIsB2b = $window.site.type === 2;
            var proposal = $scope.bkgData.purchaseResponse.proposalsRequested;
            var mandatoryFees = [];
            var totalAccommodations = siteTypeIsB2b ? $scope.bkgData.agencyMarginHotel.value : 0;
            $scope.bkgData.accommodations.forEach(function (accommodation) {
                totalAccommodations += siteTypeIsB2b ? accommodation.totalPriceRacknet : accommodation.totalPriceForcedPrice;
            });
            mandatoryFees.push({
                flexyDescription: getT('airbooking.flightprice'),
                totalPrice: $scope.bkgData.prixTotal.value,
                prices: []
            });
            mandatoryFees.push({
                flexyDescription: getT('mrfly-widget-accommodation-info.hotelprice'),
                totalPrice: totalAccommodations,
                prices: []
            });
            if (proposal.mandatoryFees) {
                proposal.mandatoryFees.forEach(function (mandatoryFee) {
                    mandatoryFee.flexyDescription = getT('mrfly-widget-basket.bookingfees');
                });
                Array.prototype.push.apply(mandatoryFees, proposal.mandatoryFees);
            }
            return mandatoryFees;
        }

        $scope.LoadVueJsWidgets = function () {
            if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {

                //nights number
                var nights = moment($scope.bkgData.accommodations[0].to).diff(moment($scope.bkgData.accommodations[0].from), "days");
                $scope.bkgData.accommodations[0].nightNumber = nights;

                getBundleWidget("mrfly-widget-accommodation-info.js").done(function () {

                    mrflyWidgets['mrfly-widget-accommodation-info'].locale = $window.lng.substring(0, 2);
                    mrflyWidgets['mrfly-widget-accommodation-info'].resources = i18next.store.data.dev.translation;
                    mrflyWidgets['mrfly-widget-accommodation-info'].accommodation = $scope.bkgData.accommodations[0];
                    mrflyWidgets['mrfly-widget-accommodation-info'].siteType = window.site.type;
                    if ($scope.bkgData.agencyMarginHotel) {
                        mrflyWidgets['mrfly-widget-accommodation-info'].agencyMarginHotel = siteTypeIsB2b ? $scope.bkgData.agencyMarginHotel.value : 0;
                    }
                })
                    .fail(function (jqxhr, settings, exception) {
                        console.log(exception);
                    });


                getBundleWidget("mrfly-widget-basket-total-price.js").done(function () {
                    mrflyWidgets['mrfly-widget-basket-total-price'].price = $scope.bkgData.totalBasketPrice();
                    var extraDetails = [];

                    var bags = basketHelper.getAdditionalBagsPriceDetails($scope.siteType, $scope.bkgData.bookingRequest.passengers);
                    var insurances = basketHelper.getInsurancePriceDetails($scope.siteType, $scope.bkgData.bookingRequest.insurance, $scope.bkgData.purchaseResponse.proposalsRequested.insurances);
                    Array.prototype.push.apply(extraDetails, bags);
                    Array.prototype.push.apply(extraDetails, insurances);

                    //Seatmap
                    var seatmapObj = JSON.parse(window.sessionStorage.getItem('selectedSeats_' + $scope.bkgData.purchaseResponse.purchaseId));
                    if (seatmapObj != null) {
                        var seatmap = basketHelper.getSeatMapPriceDetails($scope.siteType, seatmapObj);
                        Array.prototype.push.apply(extraDetails, seatmap);
                    }

                    //Discount 
                    if ($scope.bkgData.discountInfo) {
                        var discount = {
                            description: getT('airbooking.discountcode'),
                            totalPrice: $scope.bkgData.discountInfo.applicableAmount,
                            details: [],
                            type: 'DISCOUNT'
                        };

                        Array.prototype.push.apply(extraDetails, [discount]);
                    }


                    var basketDetails = basketHelper.getPriceDetails(
                        $window.site.type,
                        $scope.bkgData.purchaseResponse.proposalsRequested,
                        $scope.bkgData.accommodations,
                        siteTypeIsB2b ? $scope.bkgData.agencyMarginHotel : { value: 0, margin: 0 },
                        extraDetails,
                        $scope.bkgData.purchaseResponse.productIsTrain ? "RAIL" : "FLIGHT",
                    );

                    mrflyWidgets['mrfly-widget-basket-total-price'].basketDetails = basketDetails;
                })
                    .fail(function (jqxhr, settings, exception) {
                        console.log(exception);
                    });
            }
        };

        if (bkgData) {

            window.FLoading({ vAction: 'show' });

            $timeout(function () {  // has to be done asynchronously
                $("#frm-widget").hide();
            }, 0, false);

            $scope.bkgData = bkgData;

            bkgData.totalBasketPrice = function () {

                if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {
                    // mandatory Options
                    var proposal = $scope.bkgData.purchaseResponse.proposalsRequested;
                    var mandatoryOptionsFees = 0;
                    if (proposal.mandatoryFees) {
                        mandatoryOptionsFees = proposal.mandatoryFees.reduce(function (accumulator, mandatoryFee) {
                            return accumulator + mandatoryFee.totalPrice;
                        }, 0);
                    }
                    if (siteTypeIsB2b) {
                        // mandatory Options
                        var insurance = 0;
                        if ($scope.bkgData.insurancesSelected.isRepatriament != null && $scope.bkgData.insurancesSelected.isRepatriament.amount != null) {
                            insurance = parseFloat($scope.bkgData.insurancesSelected.isRepatriament.amount);
                        }
                        var totalAmount = ($scope.bkgData.prixTotalWithoutMargin.value - insurance + $scope.bkgData.accommodations[0].totalPriceRacknet);
                        $scope.bkgData.agencyMarginHotel.value = getAmountFromMargin(totalAmount, $scope.bkgData.agencyMarginHotel.margin);
                        var basketPrice = totalAmount + $scope.bkgData.agencyMarginHotel.value + insurance + mandatoryOptionsFees;
                        console.log("AirPaymentBodyBlue.B2B.basketPrice", basketPrice);
                        return basketPrice;
                    }
                    else {
                        var basketPrice = $scope.bkgData.prixTotal.value + $scope.bkgData.accommodations[0].totalPriceForcedPrice + mandatoryOptionsFees;
                        console.log("AirPaymentBodyBlue.B2C.basketPrice", basketPrice);
                        return basketPrice;
                    }
                } else {
                    var basketPrice = $scope.bkgData.prixTotal.value;
                    console.log("AirPaymentBodyBlue.Other.basketPrice", basketPrice);
                    return basketPrice;
                }
            };

            var keyGenerated = "";
            keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;
            bkgData.bookingRequest = tseUtil.Session.get("bookingRequest_" + keyGenerated);
            if (bkgData.purchaseResponse.productIsTrain) $scope.hideInsurance = bkgData.purchaseResponse.productIsTrain;
            else $window.hideInsurance ? $scope.hideInsurance = true : $scope.hideInsurance = false;
            if (bkgData.bookingRequest != null && bkgData.bookingRequest.requestId == bkgData.request.requestId) {
                var _insuranceSelected = tseUtil.Session.get("insurancesSelected_" + keyGenerated);
                bkgData.insurancesSelected = _insuranceSelected;

                $scope.webProcessed = true;
                if (bkgData.accommodations && bkgData.accommodations.any()) {
                    $scope.LoadVueJsWidgets();
                }

                $scope.updateBookingSummary = function () {
                    $scope.$broadcast("onBookingSummaryUpdate");
                };

                $scope.$on("onBookingSummaryUpdateCompleted", function (event, args) {

                    if (bkgData.accommodations && bkgData.accommodations.any() && $scope.isAirbus) {
                        $scope.prixTotalWithoutMargin = { value: bkgData.prixTotalWithoutMargin.value + bkgData.accommodations[0].totalPriceForcedPrice };
                    }
                    else {
                        $scope.prixTotalWithoutMargin = bkgData.prixTotalWithoutMargin;
                    }
                });
                $scope.updateBookingSummary();

            }
            else {
                $window.FLoading({ vAction: 'show' });
                window.location.href = searchUrl;
            }

            //Taxes details click
            $scope.$on("displayTaxesDetailsClick", function (event, args) {
                $scope.displayTaxesDetailModal = true;
                $scope.modalTaxesKey = args.key.replaceAll(":", "-");
                $scope.modalTaxesKey = $scope.modalTaxesKey.replaceAll("\\|", "-");
                $scope.modalTaxesKeyAux = $scope.modalTaxesKey + "-" + args.paxFares.paxType;
                $scope.modalTaxesPaxFares = args.paxFares;

                $scope.modalShowMargin = false;

                var airlines = JSON.parse(window.sessionStorage.getItem("airlines"));

                args.marketingCarriers.each(function (value, key) {

                    if (airlines != null && airlines[value].showMargin == true || value === 'AF') {
                        $scope.modalShowMargin = true;
                    }
                });

                $scope.$broadcast("updateTaxesDetailModalData", { key: $scope.modalTaxesKey, fares: $scope.modalTaxesPaxFares });

                //Remodal workaround
                $(document).find(".remodal").each(function (a, c) {
                    var d = $(c), e = d.data("remodal-options");
                    e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                        d["remodal"](e)
                });

                $timeout(function () {
                    $("[data-remodal-id='taxes-detail-modal-" + $scope.modalTaxesKeyAux + "']").remodal().open();
                },
                    200, false);
            });

            //Luggage details click
            $scope.$on("displayLuggageDetailsClick", function (event, args) {
                $scope.displayLuggageDetailModal = true;
                $scope.modalLuggageBagType = args.bagType;

                $scope.$broadcast("updateLuggageDetailModalData", { bagType: $scope.modalLuggageBagType });

                $scope.modalShowMargin = false;

                var airlines = JSON.parse(window.sessionStorage.getItem("airlines"));

                args.marketingCarriers.each(function (value, key) {

                    if (airlines != null && airlines[value].showMargin == true || value === 'AF') {
                        $scope.modalShowMargin = true;
                    }
                });

                //Remodal workaround
                $(document).find(".remodal").each(function (a, c) {
                    var d = $(c), e = d.data("remodal-options");
                    e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                        d["remodal"](e)
                });

                $timeout(function () {
                    $('[data-remodal-id="luggage-detail-modal"]').remodal().open();
                },
                    200, false);
            });

            $scope.blue.listenInput = function (event) {
                console.log('inputCallback callback !!!');
                console.log(event);
                if (event.type == "valid") {
                    if (event.element == "expiry") {
                        $scope.blue.errorDate = false;
                    }
                    if (event.element == "cryptogram") {
                        $scope.blue.errorCvv = false;
                    }
                    if (event.element == "card") {
                        $scope.blue.errorNumber = false;
                    }
                }
                $scope.$apply();
                document.getElementById('submitBtn').disabled = $scope.blue.isError();
            }


            $scope.blue.listenError = function (event) {
                console.log('error callback !!!');
                console.log(event);
                if (event.message) {
                    if (event.message.indexOf("card code") != -1) {
                        $scope.blue.errorNumber = true;
                    }
                    if (event.message.indexOf("card validity date") != -1) {
                        $scope.blue.errorDate = true;
                    }
                    if (event.message.indexOf("card verification code") != -1) {
                        $scope.blue.errorCvv = true;
                    }
                }
                $scope.$apply();
                document.getElementById('submitBtn').disabled = $scope.blue.isError();
            }
            $scope.displayWidgetForm = function () {
                try {
                    console.log("initializing be2bill");
                    var amount = $scope.bkgData.bookingResponse.paymentAmount;

                    //alert("amount 1: " + amount + "\n$scope.bkgData.bookingResponse.paymentAmount:\n" + $scope.bkgData.bookingResponse.paymentAmount);
                    if (siteTypeIsB2b) { //
                        //TO REVIEW
                        var insurance = 0;
                        if ($scope.bkgData.insurancesSelected.isRepatriament != null && $scope.bkgData.insurancesSelected.isRepatriament.amount != null) {
                            insurance = parseFloat($scope.bkgData.insurancesSelected.isRepatriament.amount);
                            insurance = 0; // now its included!
                        }
                        amount = $scope.bkgData.bookingResponse.paymentAmount = $scope.bkgData.bookingResponse.paymentAmount + insurance;
                    }
                    else {
                        //TO REVIEW (same as B2B but can change...)
                        var insurance = 0;
                        if ($scope.bkgData.insurancesSelected.isRepatriament != null && $scope.bkgData.insurancesSelected.isRepatriament.amount != null) {
                            insurance = parseFloat($scope.bkgData.insurancesSelected.isRepatriament.amount);
                            insurance = 0; // now its included!
                        }
                        amount = $scope.bkgData.bookingResponse.paymentAmount = $scope.bkgData.bookingResponse.paymentAmount + insurance;

                    }

                    if ($window.casinoTotalAmount) {
                        amount = $window.casinoTotalAmount;
                    }

                    if ($scope.isAirbus) {
                        amount = $scope.bkgData.bookingResponse.paymentAmount;
                    }

                    if (window.site.partnerId === 'servair') {
                        amount = $scope.bkgData.totalBasketPrice();
                    }

                    /*
                    if ($scope.bkgData.bookingResponse.paymentMethod.method !== 2) {
                        submitText = $i18next.t('simplepayment.acceptandpay') + " " + $filter('number')(amount, 2) + " €";
                    }
                    else {
                        submitText = $i18next.t('multiplepayment.acceptandpay') + " " + $filter('number')(amount, 2) + " €";
                    }
                    */

                    if (typeof (CurrencyManager) !== "undefined" && CurrencyManager.isEnabled() === true) {
                        console.log("displayWidgetForm A");
                        if ($scope.bkgData.bookingResponse.paymentMethod.method !== 2) {
                            if (window.lng === "fr-FR") {
                                submitText = $i18next.t('simplepayment.acceptandpay') + ' ' + $filter('number')(CurrencyManager.getPriceItemFromEuro(amount), 2) + ' ' + CurrencyManager.getCurrencySymbol(CurrencyManager.currency.id);
                            }
                            else {
                                submitText = $i18next.t('simplepayment.acceptandpay') + ' ' + CurrencyManager.getCurrencySymbol(CurrencyManager.currency.id) + $filter('number')(CurrencyManager.getPriceItemFromEuro(amount), 2);
                            }
                        }
                        else {
                            if (window.lng === "fr-FR") {
                                submitText = $i18next.t('multiplepayment.acceptandpay') + ' ' + $filter('number')(CurrencyManager.getPriceItemFromEuro(amount), 2) + ' ' + CurrencyManager.getCurrencySymbol(CurrencyManager.currency.id);
                            }
                            else {
                                submitText = $i18next.t('multiplepayment.acceptandpay') + ' ' + CurrencyManager.getCurrencySymbol(CurrencyManager.currency.id) + $filter('number')(CurrencyManager.getPriceItemFromEuro(amount), 2);
                            }
                        }

                    }
                    else {
                        console.log("displayWidgetForm B");
                        if ($scope.bkgData.bookingResponse.paymentMethod.method !== 2) {
                            submitText = $i18next.t('simplepayment.acceptandpay') + " " + $filter('number')(amount, 2) + " €";
                        }
                        else {
                            submitText = $i18next.t('multiplepayment.acceptandpay') + " " + $filter('number')(amount, 2) + " €";
                        }
                    }

                    console.log(submitText);

                    var cbOptions = {
                        "placeholder": { // placeholder of the input hosted fields
                            "holdername": $i18next.t('simplepayment.cardowner'),
                            "card": $i18next.t('simplepayment.cardnumber'),
                            "expiry": $i18next.t('simplepayment.date'),
                            "cryptogram": $i18next.t('simplepayment.ccv')
                        },
                        "labels": { // label of the hosted fields
                            "cardHolder": "",
                            "brand": "",
                            "card": "",
                            "expiry": "",
                            "validity": "",
                            "submit": submitText,
                        },
                        "style": { // define the style of the hosted-fields input
                            "card": {
                                "input": { // css syntax
                                    "font-family": "Arial,sans-serif",
                                    "font-size": "14px",
                                    "line-height": "43px",
                                    "background-color": "transparent"
                                }
                            },
                            "expiry": {
                                "input": { // css syntax
                                    "font-family": "Arial,sans-serif",
                                    "font-size": "14px",
                                    "line-height": "43px",
                                    "background-color": "transparent"
                                }
                            },
                            "cryptogram": {
                                "input": { // css syntax
                                    "font-family": "Arial,sans-serif",
                                    "font-size": "14px",
                                    "line-height": "43px",
                                    "background-color": "transparent"
                                }
                            },
                            "holdername": {
                                "input": { // css syntax
                                    "font-family": "Arial,sans-serif",
                                    "font-size": "14px",
                                    "line-height": "43px",
                                    "background-color": "transparent"
                                }
                            }
                        },
                        'errorCallback': function (event) {
                            $scope.blue.listenError(event);
                        },
                        'inputCallback': function (event) {
                            $scope.blue.listenInput(event);
                        },
                        "submitCallback": function () {
                            $scope.$apply();
                            console.log('submitCallback !!!');
                            //if (window.blue.isError())
                            //    return;
                            //$window.FLoading({ vAction: 'show' });
                            //Tracking
                            try {
                                //GTM
                                if (trackingService.isActivated()) {
                                    trackingService.pushPageData('/Flightsearch/Payment/CBwait', 'Payment CB Wait', lng, false);
                                }
                            }
                            catch (ex) {
                                console.log(ex.message);
                                $window.trackingError = ex.message;
                            }

                            window.mrflyWidgets['airbooking-process-loader'].$store.commit("showLoader");

                            var hfToken = $("#hf-token").val();
                            var selectedBrand = $("#selected-brand").val();
                            var cardHolder = $('[name=card-holder-name]').val();
                            if (window.site.partnerId == "servair") {
                                var selectedProduct = window.sessionStorage.getItem("selectedProduct");
                                var bookingNumber = null;
                                var origineAirportIata;
                                var destinationAirportIata;
                                if ($scope.bkgData != null && $scope.bkgData.bookingResponse != undefined && $scope.bkgData.bookingResponse.numbers.length > 0) {
                                    bookingNumber = $scope.bkgData.bookingResponse.numbers[0].bookingNumber;
                                    origineAirportIata = bkgData.purchaseResponse.itineraries[0].origin.airportIata;
                                    destinationAirportIata = bkgData.purchaseResponse.itineraries[0].destination.airportIata;
                                }
                                var aireportIata = origineAirportIata + destinationAirportIata;
                                $window.Typescript.Servair.Services.ServairService.doPostTotal($scope.bkgData.request.requestId, $scope.bkgData.bookingResponse.bookingId, selectedProduct, bookingNumber, aireportIata).then(function (result) {
                                    if (result.data === true) {
                                        console.log("process order from whatever 1 =========== ");
                                        var result = window.Typescript.Services.OrderServiceService.ProcessOrder($window.forceLemmingsHost, $scope.bkgData.request.requestId,
                                            $scope.bkgData.bookingRequest.purchaseId, $scope.bkgData.bookingResponse.bookingId,
                                            $scope.bkgData.bookingResponse.paymentId, $scope.pspAccountId,
                                            hfToken, selectedBrand, cardHolder);

                                        result.then(function (data) { //get redirection url from C# controler
                                            var selectedProduct = window.sessionStorage.getItem("selectedProduct");
                                            var finishUrl = tseUtil.Url.appendValueToUrl(data.data, "selectedproduct", selectedProduct);
                                            window.location.href = finishUrl;
                                        }).catch(function (err) { //on exception, redirect on error page
                                            $scope.saveSentryData(
                                                "Error",
                                                "",
                                                err,
                                                $scope.bkgData.request.requestId,
                                                $scope.bkgData.bookingRequest.purchaseId,
                                                $scope.bkgData.bookingResponse.paymentId,
                                                $scope.bkgData.bookingResponse.paymentMethod.paymentMethodReference,
                                                $scope.bkgData.bookingResponse.bookingId);
                                            window.location.href = window.site.currentAirRequest.finishErrorUrl;
                                        });
                                    }
                                    else {
                                        //redirect to error page because postTotal failed
                                        document.location.href = "/airpaymentresponse/" + $scope.bkgData.request.requestId, + "/" + $scope.bkgData.bookingResponse.bookingId + "?posttotalfailed=1";
                                    }
                                });
                            }
                            else {
                                try {
                                    console.log("process order from whatever 2 =========== ");
                                    var result = window.Typescript.Services.OrderServiceService.ProcessOrderAsync($window.forceLemmingsHost, $scope.bkgData.request.requestId,
                                        $scope.bkgData.bookingRequest.purchaseId, $scope.bkgData.bookingResponse.bookingId,
                                        $scope.bkgData.bookingResponse.paymentId, $scope.pspAccountId,
                                        hfToken, selectedBrand, cardHolder);

                                    result.then(function (data) { //get redirection url from C# controler
                                        if (data != null) {
                                            window.location.href = data;
                                        }
                                    }).catch(function (err) { //on exception, redirect on error page
                                        $scope.saveSentryData(
                                            "Error",
                                            "",
                                            err,
                                            $scope.bkgData.request.requestId,
                                            $scope.bkgData.bookingRequest.purchaseId,
                                            $scope.bkgData.bookingResponse.paymentId,
                                            $scope.bkgData.bookingResponse.paymentMethod.paymentMethodReference,
                                            $scope.bkgData.bookingResponse.bookingId);
                                        window.location.href = window.site.currentAirRequest.finishErrorUrl;
                                    });
                                }
                                catch (e) {
                                    $scope.saveSentryData(
                                        "Error",
                                        "",
                                        e,
                                        $scope.bkgData.request.requestId,
                                        $scope.bkgData.bookingRequest.purchaseId,
                                        $scope.bkgData.bookingResponse.paymentId,
                                        $scope.bkgData.bookingResponse.paymentMethod.paymentMethodReference,
                                        $scope.bkgData.bookingResponse.bookingId);
                                }
                            }
                        }
                    };


                    var casinoCallBackUrl = '/api/api/orderservice/processCasinoOrder?requestId=' + $scope.bkgData.request.requestId + '&purchaseId=' + $scope.bkgData.bookingRequest.purchaseId + "&bookingId=" + $scope.bkgData.bookingResponse.bookingId + "&paymentId=" + $scope.bkgData.bookingResponse.paymentId + "&pspAccountId=" + $scope.pspAccountId;
                    var casinoErrorCallBackUrl = window.site.currentAirRequest.finishErrorUrl;

                    if (window.site.partnerId == "servair") {
                        var selectedProduct = window.sessionStorage.getItem("selectedProduct");
                        var bookingNumber = $scope.bkgData.bookingResponse.numbers[0].bookingNumber;
                        var originAirportIata = bkgData.purchaseResponse.itineraries[0].origin.airportIata;
                        var destinationAirportIata = bkgData.purchaseResponse.itineraries[0].destination.airportIata;
                        var airportIata = originAirportIata + destinationAirportIata;
                        var token = tseUtil.Cookies.get("lid_servair_token");
                        //public string ProcessServairCasinoOrder(Guid requestId, Guid purchaseId, Guid bookingId, Guid paymentId, Guid pspAccountId,
                        //    string selectedProduct, string bookingNumber, string airportIata, string token)

                        var extraParameters = '&selectedProduct=' + selectedProduct
                            + '&bookingNumber=' + bookingNumber
                            + '&airportIata=' + airportIata
                            + '&token=' + token
                        casinoCallBackUrl = '/api/api/orderservice/processServairCasinoOrder?requestId=' + $scope.bkgData.request.requestId + '&purchaseId=' + $scope.bkgData.bookingRequest.purchaseId + "&bookingId=" + $scope.bkgData.bookingResponse.bookingId
                            + "&paymentId=" + $scope.bkgData.bookingResponse.paymentId + "&pspAccountId="
                            + $scope.pspAccountId + extraParameters;
                        casinoErrorCallBackUrl += '&posttotalfailed=1'
                    }

                    var casinoOptions = {
                        "placeholder": { // placeholder of the input hosted fields
                            "holdername": $i18next.t('simplepayment.cardowner'),
                            "card": $i18next.t('simplepayment.cardnumber'),
                            "expiry": $i18next.t('simplepayment.date'),
                            "cryptogram": $i18next.t('simplepayment.ccv')
                        },
                        "labels": { // label of the hosted fields
                            "cardHolder": "",
                            "brand": "",
                            "card": "",
                            "expiry": "",
                            "validity": "",
                            "submit": submitText,
                        },
                        "submitCallback": casinoCallBackUrl,
                        "errorCallback": function () {
                            $scope.saveSentryData(
                                "Error",
                                "",
                                new Error("Error Callback"),
                                $scope.bkgData.request.requestId,
                                $scope.bkgData.bookingRequest.purchaseId,
                                $scope.bkgData.bookingResponse.paymentId,
                                $scope.bkgData.bookingResponse.paymentMethod.paymentMethodReference,
                                $scope.bkgData.bookingResponse.bookingId);
                            window.location.href = casinoErrorCallBackUrl;
                        }
                    };

                    var currentOptions = '';
                    if ($window.casinoTotalAmount) {
                        currentOptions = casinoOptions;
                    } else {
                        //document.getElementById('submitBtn').disabled = true;
                        currentOptions = cbOptions;
                    }

                    var BluePayementJS = BluePaymentWidget.default;
                    var blue = new BluePayementJS("payment-form", currentOptions);
                    blue.init();
                    $("<p class='brand-form-group'></p>").insertAfter(".card-form-group");
                    $(".brand-form-group").append($('.brand-container'));
                    //set GRID
                    $(".hosted-fields-form").addClass("row md-gutters");
                    $(".card-form-group").addClass("col-9");
                    $(".brand-form-group").addClass("col-3");
                    var existingBrandFormGroupStyle = $(".brand-form-group").attr('style');
                    existingBrandFormGroupStyle += "; z-index: 2;"
                    $(".brand-form-group").attr('style', existingBrandFormGroupStyle);
                    $(".expiry-form-group").addClass("col-6 col-sm-6");
                    $(".cvv-form-group").addClass("col-6 col-sm-6");
                    $(".cardholder-form-group").addClass("col-12");
                    $(".blue-widget-submit").addClass("col-12");
                    $(".blue-widget-submit-input").addClass("btn btn--flex no-transform btn--primary c-w-100");

                    document.getElementById("submitBtn").addEventListener("click", $scope.sendNeoDataLayer);
                    document.getElementById('submitBtn').scrollIntoView();
                    if (!$window.casinoTotalAmount) document.getElementById('submitBtn').disabled = true;

                    $window.FLoading({ vAction: 'hide' });


                } catch (e) {
                    $scope.saveSentryData(
                        "Error",
                        "",
                        e,
                        $scope.bkgData.request.requestId,
                        $scope.bkgData.bookingRequest.purchaseId,
                        $scope.bkgData.bookingResponse.paymentId,
                        $scope.bkgData.bookingResponse.paymentMethod.paymentMethodReference,
                        $scope.bkgData.bookingResponse.bookingId);
                }
            };

            var widgetInterval = setInterval(function () {
                try {
                    console.log('BluePaymentWidget : ' + typeof BluePaymentWidget)
                    if (typeof BluePaymentWidget !== 'undefined' && $("#payment-form form").length == 0) {
                        $scope.displayWidgetForm();
                        //required because of FF
                        var formInterval = setInterval(function () {
                            $("#frm-widget").fadeIn();
                            if ($("#frm-widget").is(":visible")) {
                                clearInterval(formInterval);
                                $("#submitBtn").click(function () {
                                    $window.FLoading({ vAction: 'show' });
                                });
                                $window.FLoading({ vAction: 'hide' });
                            }
                        }, 300);
                        clearInterval(widgetInterval);
                    }
                } catch (e) {
                    $scope.saveSentryData(
                        "",
                        "TimeOut",
                        e,
                        $scope.bkgData.request.requestId,
                        $scope.bkgData.bookingRequest.purchaseId,
                        $scope.bkgData.bookingResponse.paymentId,
                        $scope.bkgData.bookingResponse.paymentMethod.paymentMethodReference,
                        $scope.bkgData.bookingResponse.bookingId);
                }
            }, 300);
        }
        else {
            $window.FLoading({ vAction: 'show' });
            $scope.saveSentryData(
                "Error",
                "",
                new Error("bkgData not exist"),
                $scope.bkgData.request.requestId,
                $scope.bkgData.bookingRequest.purchaseId,
                $scope.bkgData.bookingResponse.paymentId,
                $scope.bkgData.bookingResponse.paymentMethod.paymentMethodReference,
                $scope.bkgData.bookingResponse.bookingId);
            $window.location.href = searchUrl;
        }

        angular.element(document).ready(function () {
            setTimeout(function () {
                document.getElementById('submitBtn').scrollIntoView();
            }, 1300);
        });
    }]);